<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M1.9 21h-1v2.5l1.72-1.8-.72-.7Zm5.17-3.96a1 1 0 1 0-1.45-1.38l1.45 1.38ZM.9 13.42V21h2v-7.58h-2Zm1.72 8.27 4.45-4.65-1.45-1.38-4.44 4.65 1.44 1.38Z"
    />
    <path
      fill="currentColor"
      d="M6.34 15.35a1 1 0 1 0 0 2v-2ZM4.42 2h15.16V0H4.42v2Zm15.16 0c.83 0 1.52.69 1.52 1.56h2A3.54 3.54 0 0 0 19.58 0v2Zm1.52 1.56v10.23h2V3.56h-2Zm0 10.23c0 .88-.7 1.56-1.52 1.56v2c1.96 0 3.52-1.6 3.52-3.56h-2Zm-18.2 0V3.56h-2v10.23h2Zm0-10.23C2.9 2.69 3.6 2 4.42 2V0A3.54 3.54 0 0 0 .9 3.56h2Zm16.68 11.8H6.34v2h13.24v-2Z"
    />
    <circle
      cx="8.36"
      cy="9.08"
      r=".81"
      fill="currentColor"
    />
    <circle
      cx="12.4"
      cy="9.08"
      r=".81"
      fill="currentColor"
    />
    <circle
      cx="16.44"
      cy="9.08"
      r=".81"
      fill="currentColor"
    />
  </svg>
</template>
